define(["require", "exports", "preact/jsx-runtime", "preact", "preact/hooks", "../hooks/useQuery", "../hooks/useMutation", "../utils/environment"], function (require, exports, jsx_runtime_1, preact_1, hooks_1, useQuery_1, useMutation_1, environment_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.UserProvider = exports.UserContext = void 0;
    exports.UserContext = (0, preact_1.createContext)({ user: undefined, language: undefined, setLanguage: undefined, setCompany: undefined, isLanguageSelectorOpen: undefined, setIsLanguageSelectorOpen: undefined });
    const UserProvider = ({ accessToken, user, children }) => {
        const [_user, setUser] = (0, hooks_1.useState)(user);
        const [_isLanguageSelectorOpen, setIsLanguageSelectorOpen] = (0, hooks_1.useState)(false);
        const { invoke } = (0, useMutation_1.useUserMutation)(accessToken);
        const setLanguageAndSave = (0, hooks_1.useCallback)((language) => {
            if (_user.defaultLanguage !== language) {
                const newUser = Object.assign(Object.assign({}, _user), { defaultLanguage: language });
                invoke(newUser);
                setUser(newUser);
            }
        }, [_user]);
        const setAccountAndSave = (0, hooks_1.useCallback)((company) => {
            var _a, _b, _c, _d;
            const defaultCompany = (_a = _user === null || _user === void 0 ? void 0 : _user.profile) === null || _a === void 0 ? void 0 : _a.defaultCompany;
            if (((_b = _user === null || _user === void 0 ? void 0 : _user.profile) === null || _b === void 0 ? void 0 : _b.defaultCompany.companyId) !== company.companyId) {
                const newUser = Object.assign(Object.assign({}, _user), { profile: Object.assign(Object.assign({}, _user === null || _user === void 0 ? void 0 : _user.profile), { defaultAccountId: company.accountId, defaultCompany: company, badgedCompany: [
                            defaultCompany,
                            ...(_d = (_c = _user === null || _user === void 0 ? void 0 : _user.profile) === null || _c === void 0 ? void 0 : _c.badgedCompany) === null || _d === void 0 ? void 0 : _d.filter(bc => bc.companyId !== company.companyId)
                        ] }) });
                // invoke(newUser);
                setUser(newUser);
            }
        }, [_user]);
        const { loading, result, error } = (0, useQuery_1.useUserQuery)(accessToken);
        (0, hooks_1.useEffect)(() => {
            if (!loading && !error && result) {
                setUser(result);
            }
            else if (!loading && error) {
                setUser({ defaultLanguage: "en" });
            }
        }, [loading, error, result]);
        return ((0, jsx_runtime_1.jsx)(exports.UserContext.Provider, { value: { user: _user, language: (_user === null || _user === void 0 ? void 0 : _user.defaultLanguage) || environment_1.defaultBrowserLanguage, isLanguageSelectorOpen: _isLanguageSelectorOpen, setLanguage: setLanguageAndSave, setCompany: setAccountAndSave, setIsLanguageSelectorOpen }, children: children }));
    };
    exports.UserProvider = UserProvider;
});
