define(["require", "exports", "./environment"], function (require, exports, environment_1) {
    "use strict";
    Object.defineProperty(exports, "__esModule", { value: true });
    exports.appendPluginSearchParams = exports.forceSso = void 0;
    const forceSso = (url) => url ? `${new URL(url).origin}/wgp/redirect/?nexturl=${encodeURIComponent(url)}` : undefined;
    exports.forceSso = forceSso;
    const appendPluginSearchParams = (url) => {
        try {
            const urlObj = new URL(url);
            if (urlObj.protocol === "blob:") {
                return urlObj.href;
            }
            for (const [key, value] of (0, environment_1.pluginSearchParams)().entries()) {
                urlObj.searchParams.append(key, value);
            }
            if (urlObj.searchParams.has("tfa")) {
                urlObj.searchParams.set("tfa", window.location.host);
            }
            return urlObj.href;
        }
        catch (_a) {
            (0, environment_1.debug)("[OCC] Invalid plugin url", url);
            return "";
        }
    };
    exports.appendPluginSearchParams = appendPluginSearchParams;
});
